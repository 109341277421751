import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  feedback_online_link_main,
} from "../../ServiceConnection/serviceconnection.js";
import {
  fnExcelReport,
  formatDateString,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
function FeedbackDashboardView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const retrievedAdminId = retrieveData("staff_id");

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

  // Initialize end date as today
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(feedback_online_link_main, fd)
      .then((Response) => {
        setsBlogData(Response.data.bad_bus_searcch);

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Feedback Management
                    <div className="page-title-subheading">
                      Where You Can Check Feedback
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="main-card mb-3 card">
                  <div className="card-header card-header-view">
                    <div className="btn-actions-pane-left row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>Start Date</b>
                          </label>
                          <input
                            type="date"
                            className="form-control trio_search_ajax trio_mandatory_view"
                            name="start_date"
                            id="start_date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>End Date</b>
                          </label>
                          <input
                            type="date"
                            className="form-control trio_search_ajax trio_mandatory_view"
                            name="end_date"
                            id="end_date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-1">
                        <div className="form-group">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <b>&nbsp;</b>
                            </label>
                            <button
                              type="button"
                              className="form-control  btn btn-primary btn-sm"
                              name="end_date"
                              onClick={() => search_data()}
                            >
                              <i className="ace-icon fa fa-search icon-on-right bigger-110"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="btn-actions-pane-right btn-actions-pane-right-float ">
                        <div role="group" className="btn-group-sm btn-group">
                          <button
                            className="active btn btn-focus"
                            onClick={fnExcelReport}
                          >
                            Download Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table
                      className="align-middle mb-0 table table-borderless table-striped table-hover"
                      id="dynamic-table1"
                    >
                      <thead>
                        <tr>
                          <th className="text-center">S.No</th>
                          <th className="text-center">Entry Date</th>
                          <th className="text-center">User Name</th>
                          <th className="text-center">Email Id</th>
                          <th className="text-center">Contact No</th>
                          <th className="text-center">Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BlogData.map((blogddd, index) => (
                          <tr key={index}>
                            <td className="text-center text-muted">
                              {index + 1}
                            </td>

                            <td className="text-center">
                              {formatDateString(blogddd.entry_date)}
                            </td>
                            <td className="text-center">{blogddd.name_user}</td>
                            <td className="text-center">{blogddd.email}</td>
                            <td className="text-center">
                              {blogddd.contact_no}
                            </td>
                            <td className="text-center">{blogddd.msg}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default FeedbackDashboardView;
