import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  save_again_date_remark,
  get_all_main_lead,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleIaphabetnumberChange,
  handleNumbersChange,
  computeTodayDate,
  handleDateChange,
  computeFutureDate,
  formatDateString,
  handleSuccessSession,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function EnquiryDashboardAskAgain() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [HalllistData, seteditHalllistData] = useState([]);
  const [MenulistData, setMenulistData] = useState([]);
  const [ProcesslistData, setProcesslistData] = useState([]);
  const [data_eventtiming, setdata_eventtiming] = useState([]);
  const [data_admin_staff, setdata_admin_staff] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [RUPESSICON, setRUPESSICON] = useState("₹");
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedAdminName = retrieveData("admin_name");
  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "4";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("select_resource", 'All');
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_main_lead, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_main_lead.length > 0) {
            seteditBlogData(Response.data.message.data_main_lead[0]);
            setEditorDatMainID(
              Response.data.message.data_main_lead[0].primary_id
            );
          }

          seteditHalllistData(Response.data.message.data_halllist);
          setMenulistData(Response.data.message.data_menuselectlist);
          setProcesslistData(Response.data.message.data_process);
          setRUPESSICON(Response.data.message.rupess_icon);
          setdata_eventtiming(Response.data.message.data_eventtiming);
          setdata_admin_staff(Response.data.message.data_admin_staff);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("staff_id", retrievedAdminId);
      fd_from.append("staff_name", retrievedAdminName);
      fd_from.append("main_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(
              Response.data.message,
              "/enquiryAssignDashboardView"
            );
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const computeFutureDate_data_add = (add_event_date) => {
    const addDate = new Date(add_event_date);
    return addDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Enquiry Dashboard
                    <div className="page-title-subheading">
                      Enquiry Progress and Update
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Enquiry Process</h5>
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Enquiry Start Date :-
                        <span className="float_right_show">
                          {formatDateString(editBlogData.lead_start_date) || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Hall Name :-
                        <span className="float_right_show">
                          {HalllistData.map(
                            (blogdddddd, index) =>
                              editBlogData.lead_for_event_hall ===
                                blogdddddd.hall_name && (
                                <span key={index}>{blogdddddd.hall_name}</span>
                              )
                          )}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Enquiry Type :-
                        <span className="float_right_show">
                          {editBlogData.lead_status || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Mobile No :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_mobile_no || ""}
                        </span>
                      </button>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Booking Type :-
                        <span className="float_right_show">
                          {editBlogData.lead_type || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Person Name :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_name || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Company Name :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_company_name || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Email ID :-
                        <span className="float_right_show">
                          {editBlogData.lead_person_email_id || ""}
                        </span>
                      </button>
                    </ul>
                  </div>

                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Event Date :-
                        <span className="float_right_show">
                          {formatDateString(editBlogData.lead_event_date) || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        No of Guest :-
                        <span className="float_right_show">
                          {editBlogData.lead_event_no_of_person_come || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Referred Person Name :-
                        <span className="float_right_show">
                          {editBlogData.referred_by_person_name || ""}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Referred Mobile No :-
                        <span className="float_right_show">
                          {editBlogData.referred_by_person_no || ""}
                        </span>
                      </button>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-3">
                    <ul className="list-group">
                      <button className=" list-group-item-action list-group-item">
                        Event Name & Time :-
                        <span>
                          {data_eventtiming.map((data_get) => {
                            if (
                              Number(data_get.primary_id) ===
                              Number(editBlogData.lead_for_eventtiming_id)
                            ) {
                              return (
                                <span
                                  className="float_right_show"
                                  key={data_get.primary_id}
                                >
                                  {editBlogData.event_list_name}{" "}
                                  {data_get.timing_name} ({data_get.timing_from}{" "}
                                  - {data_get.timing_to})
                                </span>
                              );
                            }
                            return null; // You may want to handle the case when there is no match
                          })}
                        </span>
                      </button>
                      <button className=" list-group-item-action list-group-item">
                        Assign Staff Name :-
                        <span className="float_right_show">
                          {data_admin_staff.map((data_get) => {
                            if (
                              Number(data_get.primary_id) ===
                              Number(editBlogData.lead_assign_to_staff)
                            ) {
                              return (
                                <span
                                  className="float_right_show"
                                  key={data_get.primary_id}
                                >
                                  {data_get.admin_name}
                                </span>
                              );
                            }
                            return null;
                          })}

                          {data_admin_staff.every(
                            (data_get) =>
                              Number(data_get.primary_id) !==
                              Number(editBlogData.lead_assign_to_staff)
                          ) && (
                            <span className="float_right_show">
                              {retrievedAdminName}
                            </span>
                          )}
                        </span>
                      </button>
                    </ul>
                  </div>
                  <div className="col-md-12 mb-3">
                    <ul className="list-group">
                      {editBlogData.lead_person_address &&
                        editBlogData.lead_person_city && (
                          <button className="list-group-item-action list-group-item">
                            Full Address:
                            <span className="float_right_show">
                              {editBlogData.lead_person_address && (
                                <span>
                                  {editBlogData.lead_person_address},{" "}
                                </span>
                              )}
                              {editBlogData.lead_person_city && (
                                <span>{editBlogData.lead_person_city}, </span>
                              )}
                              {editBlogData.lead_person_country && (
                                <span>{editBlogData.lead_person_country}</span>
                              )}
                            </span>
                          </button>
                        )}
                      {editBlogData.special_requirement &&
                        editBlogData.special_requirement.length > 2 && (
                          <button className=" list-group-item-action list-group-item">
                            Special Requirement :-
                            <span className="float_right_show">
                              {editBlogData.special_requirement || ""}
                            </span>
                          </button>
                        )}
                    </ul>
                  </div>
                  {MenulistData.length > 0 && (
                    <div className="col-md-12 mb-3">
                      <h5 className="card-title">Menu List</h5>
                      <ul className="list-group">
                        {MenulistData.map((blogdddddd, index) => (
                          <button
                            className=" list-group-item-action list-group-item"
                            key={index}
                          >
                            {blogdddddd.menu_name}
                            <span className="float_right_show">
                              {RUPESSICON}{" "}
                              {blogdddddd.menu_discount_price > 0 ? (
                                <span>{blogdddddd.menu_discount_price}</span>
                              ) : (
                                <span>{blogdddddd.menu_price}</span>
                              )}
                            </span>
                          </button>
                        ))}
                      </ul>
                    </div>
                  )}
                  {ProcesslistData.length > 0 && (
                    <div className="col-md-12 mb-3">
                      <h5 className="card-title">Enquiry Progress</h5>
                      <ul className="list-group">
                        {ProcesslistData.map((blogdddddd, index) => (
                          <button
                            className=" list-group-item-action list-group-item"
                            key={index}
                          >
                            <div className="row">
                              <div className="col-md-4 ">
                                {" "}
                                {formatDateString(blogdddddd.again_ask_date)}
                              </div>
                              <div className="col-md-2 ">
                                {" "}
                                <span className="min_center">
                                  {blogdddddd.meeting_minutes} Min{" "}
                                </span>
                              </div>
                              <div className="col-md-6 ">
                                {" "}
                                <span className="float_right_show">
                                  {blogdddddd.remark}
                                </span>
                              </div>
                            </div>
                          </button>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                {editBlogData.status_for_lead === "1" && (
                  <form className="needs-validation" id="CarrerformData">
                    <div className="form-row">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="validationCustom01">
                          Next Date
                          <span className="red_show">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control  trio_mandatory"
                          name="again_ask_date"
                          id="again_ask_date"
                          min={computeTodayDate()} // Replace with the maximum date you want to allow
                          max={computeFutureDate_data_add(
                            editBlogData.lead_event_date
                          )} // Replace with the maximum date you want to allow
                          placeholder="Enter Next Date"
                          onChange={handleDateChange}
                        />
                        <span className="condition_error"></span>
                      </div>

                      <div className="col-md-3 mb-3">
                        <label htmlFor="validationCustom01">
                          Call Duration
                          <span className="red_show">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control  trio_mandatory length_min"
                          name="meeting_minutes"
                          id="meeting_minutes"
                          maxLength={4}
                          onInput={handleNumbersChange}
                          placeholder="Enter Call Duration"
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="validationCustom01">Remark</label>
                        <textarea
                          type="text"
                          className="form-control  "
                          name="remark"
                          id="remark"
                          maxLength={400}
                          onInput={handleIaphabetnumberChange}
                          placeholder="Enter Remark"
                        ></textarea>
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() =>
                        handleSaveChangesdynamic(
                          "CarrerformData",
                          save_again_date_remark
                        )
                      }
                    >
                      Submit
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default EnquiryDashboardAskAgain;
