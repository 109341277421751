import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  get_all_customer_booking,
  delete_update_data,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handleLinkClick,
  handleError,
  handleConfimDeleteClick,
  handleNumbersChange,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
import DeletIcon from "../../Assests/images/delete.png";
function CustomerBookingDashboardView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const [RUPESSICON, setRUPESSICON] = useState("₹");
  const retrievedAdminId = retrieveData("staff_id");
  const admin_profession = retrieveData("admin_profession");
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

  // Initialize end date as today
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  const [SelectResource, setSelectResource] = useState("");
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(startDate, endDate, SelectResource, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, SelectResource, flag, call_id);
  };
  const master_data_get = async (
    start_date,
    end_date,
    select_resource,
    flag,
    call_id
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("select_resource", select_resource);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_customer_booking, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsBlogData(Response.data.message.data_main_lead);
          setRUPESSICON(Response.data.message.rupess_icon);
          console.log(Response.data.message.data_main_lead);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("id_for_delete", call_id);
      fd.append("flag_for", "16");
      fd.append("for_status_final", "d");
      await server_post_data(delete_update_data, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            const start_date = "";
            const end_date = "";
            const flag = "1";
            const call_id = "0";
            master_data_get(start_date, end_date, flag, call_id);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Confirm Booking Dashboard
                    <div className="page-title-subheading">
                      Where You Can Check Confirm Booking
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card-header card-header-view">
                  <div className="btn-actions-pane-left row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          <b>Start Date</b>
                        </label>
                        <input
                          type="date"
                          className="form-control trio_search_ajax trio_mandatory_view"
                          name="start_date"
                          id="start_date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          <b>End Date</b>
                        </label>
                        <input
                          type="date"
                          className="form-control trio_search_ajax trio_mandatory_view"
                          name="end_date"
                          id="end_date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Mobile No<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_no"
                        name="lead_person_mobile_no"
                        id="lead_person_mobile_no"
                        maxLength={10}
                        onInput={handleNumbersChange}
                        placeholder="Enter Mobile No"
                        defaultValue={SelectResource}
                        onChange={(e) => setSelectResource(e.target.value)}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-sm-1">
                      <div className="form-group">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>&nbsp;</b>
                          </label>
                          <button
                            type="button"
                            className="form-control  btn btn-primary btn-sm"
                            name="end_date"
                            onClick={() => search_data()}
                          >
                            <i className="ace-icon fa fa-search icon-on-right bigger-110"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 card">
                <div class="card-header">
                  <ul class="nav nav-justified">
                    <li class="nav-item">
                      <a
                        data-toggle="tab"
                        href="#tab-eg7-0"
                        class="nav-link active show"
                      >
                        Due Payment
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        data-toggle="tab"
                        href="#tab-eg7-1"
                        class="nav-link show"
                      >
                        Complete Booking
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card-body">
                  <div class="tab-content">
                    <div
                      class="tab-pane active show"
                      id="tab-eg7-0"
                      role="tabpanel"
                    >
                      <div className="row">
                        {BlogData.filter(
                          (cake) => cake.status_for_complete_close === "0"
                        ).map((blogddd, index) => (
                          <div className="col-md-6" key={index}>
                            <div
                              className={`card-shadow-warning  mb-3 card card-body  cardTwo cardNeutral`}
                            >
                              <div className="cardtwodetails">
                                <div className="cardtwodetailsHead d-flex justify-content-between">
                                  <h6 style={{ color: "#1177a0" }}>
                                    {blogddd.customer_person_name}
                                    <div
                                      className={`ml-auto badge icon_right ${
                                        blogddd.status_for_complete_close === ""
                                          ? "card-brageNeutral"
                                          : blogddd.status_for_complete_close ===
                                            "c"
                                          ? "card-brageGreen"
                                          : blogddd.status_for_complete_close ===
                                            "d"
                                          ? "card-brageRed"
                                          : ""
                                      }`}
                                    >
                                      {blogddd.status_for_complete_close === ""
                                        ? "Running"
                                        : blogddd.status_for_complete_close ===
                                          "c"
                                        ? "Complete"
                                        : blogddd.status_for_complete_close ===
                                          "d"
                                        ? "Cancel"
                                        : ""}
                                    </div>
                                  </h6>

                                  {admin_profession === "0" &&
                                    blogddd.status_for_complete_close ===
                                      "0" && (
                                      <button
                                        type="button"
                                        tabIndex="0"
                                        className="deletbtnAdmin"
                                        onClick={() =>
                                          master_data_action(blogddd.primary_id)
                                        }
                                      >
                                        <img src={DeletIcon} alt="icon" />
                                      </button>
                                    )}
                                </div>
                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Person Name :-
                                    <span className="float_right_show">
                                      {blogddd.customer_person_name}
                                    </span>
                                  </button>
                                  <button className=" list-group-item-action list-group-item">
                                    Mobile No :-
                                    <span className="float_right_show">
                                      {blogddd.person_mobile_no}
                                    </span>
                                  </button>
                                </div>
                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Event Date :-
                                    <span className="float_right_show">
                                      {blogddd.event_date}
                                    </span>
                                  </button>
                                  <button className=" list-group-item-action list-group-item">
                                    Event Time :-
                                    <span className="float_right_show">
                                      {blogddd.customer_for_eventtiming_name}
                                    </span>
                                  </button>
                                </div>

                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Advance Amount :-
                                    <span className="float_right_show">
                                      {RUPESSICON} {blogddd.advance_amount}
                                    </span>
                                  </button>
                                </div>
                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Final Amount :-
                                    <span className="float_right_show">
                                      {RUPESSICON} {blogddd.final_amount}
                                    </span>
                                  </button>
                                </div>

                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Requirement :-
                                    <span className="float_right_show">
                                      {blogddd.special_requirement}
                                    </span>
                                  </button>
                                </div>
                                {blogddd.status_for_complete_close === "2" ? (
                                  <div className="cardtwodetailsBodyFooter">
                                    {" "}
                                    <h6
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                      }}
                                    ></h6>
                                  </div>
                                ) : (
                                  <div className="cardtwodetailsBodyFooter">
                                    <p></p>

                                    <h6
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {blogddd.status_for_complete_close ===
                                      "0" ? (
                                        <Link
                                          onClick={() =>
                                            handleLinkClick(
                                              "/CustomerBookingDashboardDetails/" +
                                                blogddd.primary_id
                                            )
                                          }
                                          style={{ color: "red" }}
                                        >
                                          Pay Outstanding Amount
                                        </Link>
                                      ) : null}
                                      {blogddd.status_for_complete_close ===
                                      "1" ? (
                                        <Link
                                          onClick={() =>
                                            handleLinkClick(
                                              "/CustomerBookingInvoice/" +
                                                blogddd.primary_id,
                                              "_blank"
                                            )
                                          }
                                          style={{ color: "red" }}
                                          target="_blank"
                                        >
                                          Print Invoice
                                        </Link>
                                      ) : null}
                                    </h6>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div class="tab-pane show" id="tab-eg7-1" role="tabpanel">
                      <div className="row">
                        {BlogData.filter(
                          (cake) => cake.status_for_complete_close === "1"
                        ).map((blogddd, index) => (
                          <div className="col-md-6" key={index}>
                            <div
                              className={`card-shadow-warning  mb-3 card card-body  cardTwo cardNeutral`}
                            >
                              <div className="cardtwodetails">
                                <div className="cardtwodetailsHead d-flex justify-content-between">
                                  <h6 style={{ color: "#1177a0" }}>
                                    {blogddd.customer_person_name}
                                    <div
                                      className={`ml-auto badge icon_right ${
                                        blogddd.status_for_complete_close === ""
                                          ? "card-brageNeutral"
                                          : blogddd.status_for_complete_close ===
                                            "c"
                                          ? "card-brageGreen"
                                          : blogddd.status_for_complete_close ===
                                            "d"
                                          ? "card-brageRed"
                                          : ""
                                      }`}
                                    >
                                      {blogddd.status_for_complete_close === ""
                                        ? "Running"
                                        : blogddd.status_for_complete_close ===
                                          "c"
                                        ? "Complete"
                                        : blogddd.status_for_complete_close ===
                                          "d"
                                        ? "Cancel"
                                        : ""}
                                    </div>
                                  </h6>

                                  {admin_profession === "0" &&
                                    blogddd.status_for_complete_close ===
                                      "0" && (
                                      <button
                                        type="button"
                                        tabIndex="0"
                                        className="deletbtnAdmin"
                                        onClick={() =>
                                          master_data_action(blogddd.primary_id)
                                        }
                                      >
                                        <img src={DeletIcon} alt="icon" />
                                      </button>
                                    )}
                                </div>
                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Person Name :-
                                    <span className="float_right_show">
                                      {blogddd.customer_person_name}
                                    </span>
                                  </button>
                                  <button className=" list-group-item-action list-group-item">
                                    Mobile No :-
                                    <span className="float_right_show">
                                      {blogddd.person_mobile_no}
                                    </span>
                                  </button>
                                </div>
                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Event Date :-
                                    <span className="float_right_show">
                                      {blogddd.event_date}
                                    </span>
                                  </button>
                                  <button className=" list-group-item-action list-group-item">
                                    Event Time :-
                                    <span className="float_right_show">
                                      {blogddd.customer_for_eventtiming_name}
                                    </span>
                                  </button>
                                </div>

                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Advance Amount :-
                                    <span className="float_right_show">
                                      {RUPESSICON} {blogddd.advance_amount}
                                    </span>
                                  </button>
                                </div>
                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Final Amount :-
                                    <span className="float_right_show">
                                      {RUPESSICON} {blogddd.final_amount}
                                    </span>
                                  </button>
                                </div>

                                <div className="cardtwodetailsBody">
                                  <button className=" list-group-item-action list-group-item">
                                    Requirement :-
                                    <span className="float_right_show">
                                      {blogddd.special_requirement}
                                    </span>
                                  </button>
                                </div>
                                {blogddd.status_for_complete_close === "2" ? (
                                  <div className="cardtwodetailsBodyFooter">
                                    {" "}
                                    <h6
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                      }}
                                    ></h6>
                                  </div>
                                ) : (
                                  <div className="cardtwodetailsBodyFooter">
                                    <p></p>

                                    <h6
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {blogddd.status_for_complete_close ===
                                      "0" ? (
                                        <Link
                                          onClick={() =>
                                            handleLinkClick(
                                              "/CustomerBookingDashboardDetails/" +
                                                blogddd.primary_id
                                            )
                                          }
                                          style={{ color: "red" }}
                                        >
                                          Pay Outstanding Amount
                                        </Link>
                                      ) : null}
                                      {blogddd.status_for_complete_close ===
                                      "1" ? (
                                        <Link
                                          onClick={() =>
                                            handleLinkClick(
                                              "/CustomerBookingInvoice/" +
                                                blogddd.primary_id,
                                              "_blank"
                                            )
                                          }
                                          style={{ color: "red" }}
                                          target="_blank"
                                        >
                                          Print Invoice
                                        </Link>
                                      ) : null}
                                    </h6>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default CustomerBookingDashboardView;
