import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  save_update_main_lead,
  get_all_main_lead,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  computeTodayDate,
  handleDateChange,
  computeFutureDate,
  handleNumbersDecimalChange,
  handleSuccessSession,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import CreatableSelect from "react-select/creatable";
function EnquiryDashboardAdd() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [totalAmount, setTotalAmount] = useState("");
  const [editBlogData, seteditBlogData] = useState([]);
  const [HalllistData, seteditHalllistData] = useState([]);
  const [MenulistData, setMenulistData] = useState([]);
  const [EventTiminglistData, setEventTiminglistData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [error, setError] = useState("");
  const [error_data, seterror_data] = useState("");
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedAdminName = retrieveData("admin_name");
  const admin_profession = retrieveData("admin_profession");
  const [selectedLeadValue, setselectedLeadValue] = useState();
  const [AddOnAmount, setAddOnAmount] = useState(0);
  const [gstfinalAmount, setgstAmount] = useState("0.00");
  const [finaltotalAmount, setfinaltotalAmount] = useState("");
  const [selectedValue, setSelectedValue] = useState("0"); // Initial valuex`
  const [remembermasterdata, setremembermasterdata] = useState([]);
  const [seteventlistname, setseteventlistname] = useState([]);
  const [RUPESSICON, setRUPESSICON] = useState("₹");
  const [StafflistData, setStafflistData] = useState([]);
  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    const input = document.getElementById("searchInput");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ["(cities)"], // Restrict results to cities
    });

    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();
      let full_address = place.address_components;
      let length_data = place.address_components.length;
      let citys = "";
      let state = "";
      let country = "";
      let tehsil = "";

      for (let i = 0; i < length_data; i++) {
        if (full_address[i].types[0] === "administrative_area_level_1") {
          state = full_address[i].long_name;
        } else if (full_address[i].types[0] === "country") {
          country = full_address[i].long_name;
        } else if (full_address[i].types[0] === "administrative_area_level_2") {
          citys = full_address[i].long_name;
        } else if (full_address[i].types[0] === "locality") {
          tehsil = full_address[i].long_name;
        }
      }
      if (tehsil !== "") {
        citys = tehsil;
      }
      document.getElementById("lead_person_city").value = citys;
      document.getElementById("lead_person_state").value = state;
      document.getElementById("lead_person_country").value = country;
    });
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("select_resource", "All");
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_main_lead, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_main_lead.length > 0) {
            seteditBlogData(Response.data.message.data_main_lead[0]);
            setEditorDatMainID(
              Response.data.message.data_main_lead[0].primary_id
            );
            setTotalAmount(
              Response.data.message.data_main_lead[0].lead_total_amount
            );
            setAdvanceAmount(
              Response.data.message.data_main_lead[0].lead_advance_amount
            );
            setselectedLeadValue(
              Response.data.message.data_main_lead[0].lead_type
            );
            setAddOnAmount(
              Response.data.message.data_main_lead[0].add_on_amount
            );
            setfinaltotalAmount(
              Response.data.message.data_main_lead[0].lead_total_amount
            );
          }
          setStafflistData(Response.data.message.data_admin_staff);
          seteditHalllistData(Response.data.message.data_halllist);
          setMenulistData(Response.data.message.data_menulist);
          setEventTiminglistData(Response.data.message.data_eventtiming);
          setRUPESSICON(Response.data.message.rupess_icon);
          setseteventlistname(Response.data.message.data_evenlist);
          const foodOptions2 = Response.data.message.data_menulist.map(
            (food) => ({
              value:
                food.primary_id +
                "~@~" +
                food.menu_name +
                "~@~" +
                food.menu_price +
                "~@~" +
                food.menu_discount_price, // You can modify this to suit your needs
              label: food.menu_name, // Capitalize the first letter
            })
          );

          setremembermasterdata(foodOptions2);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    // Assuming "assign_staff_id" is a <select> element
    var dropdown = document.getElementById("assign_staff_id");
    var selectedOption;
    // Retrieve the selected option
    try {
      selectedOption = dropdown.options[dropdown.selectedIndex];
    } catch (err) {
      selectedOption = dropdown;
    }
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);

      let assign_staff_id = selectedOption.value.split("~@~"); // id of table

      fd_from.append("lead_taken_by_staff", retrievedAdminId);
      fd_from.append("lead_taken_by_staff_name", retrievedAdminName);
      fd_from.append("lead_assign_by_staff", assign_staff_id[0]);
      fd_from.append("lead_assign_to_staff", assign_staff_id[1]);
      fd_from.append("status_for_lead", assign_staff_id[2]);
      fd_from.append("gst_amount", gstfinalAmount);
      fd_from.append("main_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            if (assign_staff_id[2] === "0") {
              handleSuccessSession(
                Response.data.message,
                "/enquiryDashboardView"
              );
            } else {
              handleSuccessSession(
                Response.data.message,
                "/enquiryAssignDashboardView"
              );
            }

            if (editorDataMainID === "0") {
              empty_form(form_data);
            }
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleMenuChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const selectedMenuDetails = selectedOptions.map((option) =>
      option.value.split("~@~")
    );
    // Calculate total amount based on selected menu prices
    const newTotalAmount = selectedMenuDetails.reduce(
      (total, [, , menuPrice, menuDiscount]) => {
        let price = parseFloat(menuPrice);
        if (menuDiscount > 0) {
          price = parseFloat(menuDiscount);
        }
        return total + price;
      },
      0
    );

    let newValue = document.getElementById("gst_per").value;
    let add_on_amount = document.getElementById("add_on_amount").value;
    let lead_advance_amount = document.getElementById(
      "lead_advance_amount"
    ).value;
    let lead_event_no_of_person_come = Number(
      document.getElementById("lead_event_no_of_person_come").value
    );
    let amount_show = lead_event_no_of_person_come * Number(newTotalAmount);

    calculategstfinalamount(amount_show, add_on_amount, newValue);
    setTotalAmount(amount_show);
    document.getElementById("lead_total_amount").value = amount_show;
    if (Number(lead_advance_amount) > Number(amount_show)) {
      document.getElementById("lead_advance_amount").value = 0;
    }
  };

  const NoOfGuestChange = (event) => {
    let amount_show = 0;

    calculategstfinalamount(amount_show, 0, 0);
    setTotalAmount(amount_show);
    document.getElementById("lead_total_amount").value = amount_show;
    document.getElementById("lead_advance_amount").value = 0;
  };

  const handleBlur = () => {
    const totalAmountValue = parseFloat(totalAmount);
    const advanceAmountValue = parseFloat(advanceAmount);
    console.log(totalAmountValue > advanceAmountValue);
    if (
      !isNaN(totalAmountValue) &&
      !isNaN(advanceAmountValue) &&
      totalAmountValue > advanceAmountValue
    ) {
      setAdvanceAmount("0");
    }
  };

  const handleTotalAmountChange = (e) => {
    const enteredTotalAmount = e.target.value;
    setTotalAmount(enteredTotalAmount);

    // Check if Advance Amount is greater than Total Amount
    if (
      advanceAmount !== "" &&
      parseFloat(advanceAmount) > parseFloat(enteredTotalAmount)
    ) {
      setError("Advance Amount cannot be greater than Total Amount.");
      setAdvanceAmount("0");
    } else {
      setError("");
    }
  };

  const handleAdvanceAmountChange = (event) => {
    const enteredAdvanceAmount = parseFloat(event.target.value);
    const totalAmount = parseFloat(
      document.getElementById("final_amount").value
    );
    if (enteredAdvanceAmount > totalAmount) {
      event.target.value = "";
      setError("Advance amount cannot be greater than total amount");
    } else {
      setAdvanceAmount(enteredAdvanceAmount);
      setError("");
    }
  };

  const calculategstfinalamount = (
    lead_total_amount,
    add_on_amount,
    gst_per
  ) => {
    // Calculate GST amount

    let base_amount = Number(lead_total_amount) + Number(add_on_amount);
    const gstAmount = (Number(base_amount) * gst_per) / 100;
    // Calculate total amount including GST
    setgstAmount(gstAmount);
    let final_total_amount = Number(base_amount) + Number(gstAmount);

    document.getElementById("final_amount_set").value = final_total_amount;
    document.getElementById("final_amount").value = final_total_amount;
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    let lead_total_amount = document.getElementById("lead_total_amount").value;
    let add_on_amount = document.getElementById("add_on_amount").value;
    calculategstfinalamount(lead_total_amount, add_on_amount, newValue);
  };

  const handleAddonChange = (event) => {
    const add_on_amount = event.target.value;
    setAddOnAmount(add_on_amount);
    let lead_total_amount = document.getElementById("lead_total_amount").value;
    let newValue = document.getElementById("gst_per").value;
    calculategstfinalamount(lead_total_amount, add_on_amount, newValue);
  };

  const handleSelectChange_list = (event) => {
    setselectedLeadValue(event.target.value);
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Enquiry Dashboard
                    <div className="page-title-subheading">Add Enquiry</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add/Edit Enquiry</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    {admin_profession === "0" ? (
                      <div className="col-md-3 mb-3">
                        <label htmlFor="validationCustom01">
                          Assign to <span className="red_show">*</span>
                        </label>
                        <select
                          className="form-control trio_mandatory"
                          name="assign_staff_id"
                          id="assign_staff_id"
                          placeholder="Select Assign Staff"
                        >
                          <option value="" disabled selected hidden>
                            Select Assign Staff
                          </option>
                          <option
                            value={
                              retrievedAdminId +
                              "~@~" +
                              retrievedAdminId +
                              "~@~1"
                            }
                          >
                            Admin
                          </option>
                          {StafflistData.map((blogdddddd, index) => (
                            <option
                              key={index}
                              value={
                                blogdddddd.primary_id +
                                "~@~" +
                                blogdddddd.primary_id +
                                "~@~1"
                              }
                              selected={
                                editBlogData.lead_assign_to_staff ===
                                blogdddddd.primary_id
                              }
                            >
                              {blogdddddd.admin_name}({" "}
                              {blogdddddd.admin_mobile_no})
                            </option>
                          ))}
                          <option value="0~@~0~@~0">Without Assign</option>
                        </select>

                        <span className="condition_error"></span>
                      </div>
                    ) : (
                      <input
                        type="hidden"
                        className="form-control  "
                        name="assign_staff_id"
                        id="assign_staff_id"
                        defaultValue={
                          retrievedAdminId + "~@~" + retrievedAdminId + "~@~1"
                        }
                      />
                    )}

                    <div className="col-md-3 mb-3 hidden">
                      <label htmlFor="validationCustom01">
                        Enquiry Start Date
                        <span className="red_show">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control  trio_mandatory"
                        name="lead_start_date"
                        id="lead_start_date"
                        min={computeTodayDate()} // Replace with the maximum date you want to allow
                        max={computeFutureDate()} // Replace with the maximum date you want to allow
                        placeholder="Enter Enquiry Start Date"
                        defaultValue={
                          editBlogData.lead_start_date || computeTodayDate()
                        }
                        onChange={handleDateChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Hall Name <span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_for_event_hall"
                        id="lead_for_event_hall"
                      >
                        {HalllistData.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={blogdddddd.hall_name}
                            selected={
                              editBlogData.lead_for_event_hall ===
                              blogdddddd.hall_name
                            }
                          >
                            {blogdddddd.hall_name}
                          </option>
                        ))}
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Enquiry Type<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_status"
                        id="lead_status"
                        placeholder="Select Enquiry Type"
                      >
                        <option value="" disabled selected hidden>
                          Select Enquiry Type
                        </option>
                        <option
                          value="Hot"
                          selected={editBlogData.lead_status === "Hot"}
                        >
                          Hot
                        </option>
                        <option
                          value="Cold"
                          selected={editBlogData.lead_status === "Cold"}
                        >
                          Cold
                        </option>
                        <option
                          value="Warm"
                          selected={editBlogData.lead_status === "Warm"}
                        >
                          Warm
                        </option>
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Booking Type<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_type"
                        id="lead_type"
                        value={selectedLeadValue}
                        placeholder="Select Booking Type"
                        onChange={handleSelectChange_list}
                      >
                        <option value="" disabled selected hidden>
                          Select Booking Type
                        </option>
                        <option value="Enquiry">Enquiry</option>
                        <option value="Booking">Booking</option>
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Name <span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="event_list_name"
                        placeholder="Select Event Name"
                        id="event_list_name"
                      >
                        <option value="" disabled selected hidden>
                          Select Event Name
                        </option>
                        {seteventlistname.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={blogdddddd.event_list_name}
                            selected={
                              editBlogData.event_list_name ===
                              blogdddddd.event_list_name
                            }
                          >
                            {blogdddddd.event_list_name}
                          </option>
                        ))}
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Person Name <span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lead_person_name"
                        id="lead_person_name"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Person Name"
                        defaultValue={editBlogData.lead_person_name || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">Company Name</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_company_name"
                        id="lead_person_company_name"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Company Name"
                        defaultValue={
                          editBlogData.lead_person_company_name || ""
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Source From<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_source"
                        id="lead_source"
                      >
                        <option value="" disabled selected hidden>
                          Select Source From
                        </option>
                        <option
                          value="Walk-In"
                          selected={editBlogData.lead_source === "Walk-In"}
                        >
                          Walk-In
                        </option>
                        <option
                          value="Facebook"
                          selected={editBlogData.lead_source === "Facebook"}
                        >
                          Facebook
                        </option>
                        <option
                          value="JustDial"
                          selected={editBlogData.lead_source === "JustDial"}
                        >
                          JustDial
                        </option>
                        <option
                          value="Instagram"
                          selected={editBlogData.lead_source === "Instagram"}
                        >
                          Instagram
                        </option>
                        <option
                          value="Call"
                          selected={editBlogData.lead_source === "Call"}
                        >
                          Call
                        </option>
                        <option
                          value="Website"
                          selected={editBlogData.lead_source === "Website"}
                        >
                          Website
                        </option>
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Mobile No<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_no"
                        name="lead_person_mobile_no"
                        id="lead_person_mobile_no"
                        maxLength={10}
                        onInput={handleNumbersChange}
                        placeholder="Enter Mobile No"
                        defaultValue={editBlogData.lead_person_mobile_no || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01"> Email ID</label>
                      <input
                        type="text"
                        className="form-control "
                        name="lead_person_email_id"
                        id="lead_person_email_id"
                        maxLength={100}
                        onInput={handleEmailChange}
                        placeholder="Enter Email"
                        defaultValue={editBlogData.lead_person_email_id || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Search City<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="searchInput"
                        id="searchInput"
                        maxLength={200}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City Name"
                        defaultValue={editBlogData.lead_person_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Address<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lead_person_address"
                        id="lead_person_address"
                        maxLength={200}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Address"
                        defaultValue={editBlogData.lead_person_address || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row hidden">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01"> City</label>
                      <input
                        type="text"
                        className="form-control  searchInput_google"
                        name="lead_person_city"
                        id="lead_person_city"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City"
                        defaultValue={editBlogData.lead_person_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01"> State</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_state"
                        id="lead_person_state"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter State"
                        defaultValue={editBlogData.lead_person_state || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Country</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_country"
                        id="lead_person_country"
                        maxLength={100}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Country"
                        defaultValue={
                          editBlogData.lead_person_country || "India"
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Date<span className="red_show">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control  trio_mandatory"
                        name="lead_event_date"
                        id="lead_event_date"
                        min={computeTodayDate()} // Replace with the maximum date you want to allow
                        max={computeFutureDate()} // Replace with the maximum date you want to allow
                        placeholder="Enter Event date"
                        onChange={handleDateChange}
                        defaultValue={editBlogData.lead_event_date || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Timing<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_for_eventtiming_full"
                        id="lead_for_eventtiming_full"
                      >
                        <option value="" disabled selected hidden>
                          Select Event Timing
                        </option>
                        {EventTiminglistData.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={
                              blogdddddd.primary_id +
                              "~@~" +
                              blogdddddd.timing_name
                            }
                            selected={
                              editBlogData.lead_for_eventtiming_id ===
                              blogdddddd.primary_id
                            }
                          >
                            {blogdddddd.timing_name}({" "}
                            {blogdddddd.timing_from +
                              "-" +
                              blogdddddd.timing_to}
                            )
                          </option>
                        ))}
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        No of Guest<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory length_min"
                        name="lead_event_no_of_person_come"
                        id="lead_event_no_of_person_come"
                        maxLength={5}
                        onInput={handleNumbersChange}
                        onChange={NoOfGuestChange}
                        placeholder="Enter No of Guest"
                        defaultValue={
                          editBlogData.lead_event_no_of_person_come || ""
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  {selectedLeadValue === "Booking" && (
                    <>
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="validationCustom01">
                            Menu Selected<span className="red_show">*</span>
                          </label>
                          <select
                            multiple
                            id="menu_selected_id"
                            name="menu_selected_id"
                            className="form-control  trio_mandatory"
                            onChange={handleMenuChange}
                          >
                            {MenulistData.map((blogdddddd, index) => (
                              <option
                                key={index}
                                value={
                                  blogdddddd.primary_id +
                                  "~@~" +
                                  blogdddddd.menu_name +
                                  "~@~" +
                                  blogdddddd.menu_price +
                                  "~@~" +
                                  blogdddddd.menu_discount_price
                                }
                              >
                                {blogdddddd.menu_discount_price > 0
                                  ? `${blogdddddd.menu_name} ₹ ${Number(
                                      blogdddddd.menu_discount_price
                                    )} Per Person`
                                  : `${blogdddddd.menu_name} ₹ ${Number(
                                      blogdddddd.menu_price
                                    )} Per Person`}
                              </option>
                            ))}
                          </select>

                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-3 mb-3">
                          <label htmlFor="validationCustom01">
                            Total Amount <span className="red_show">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control trio_mandatory length_min"
                            name="lead_total_amount"
                            id="lead_total_amount"
                            maxLength={10}
                            onInput={handleNumbersDecimalChange}
                            onBlur={handleBlur}
                            placeholder="Enter Total Amount"
                            defaultValue={totalAmount}
                            onChange={handleTotalAmountChange}
                          />
                          <span className="condition_error"></span>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="validationCustom01">
                            Addon Amount <span className="red_show">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control trio_mandatory length_min"
                            name="add_on_amount"
                            id="add_on_amount"
                            maxLength={10}
                            onInput={handleNumbersDecimalChange}
                            placeholder="Enter Addon Amount"
                            defaultValue={AddOnAmount}
                            onChange={handleAddonChange}
                          />
                          <span className="condition_error"></span>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="validationCustom01">
                            GST% <span className="red_show">*</span>
                          </label>
                          <select
                            className="form-control trio_mandatory length_min"
                            name="gst_per"
                            id="gst_per"
                            value={selectedValue}
                            onChange={handleSelectChange}
                          >
                            <option value="0">0%</option>
                            <option value="5">5%</option>
                            <option value="12">12%</option>
                            <option value="18">18%</option>
                            <option value="28">28%</option>
                          </select>
                          <span className="condition_error"></span>
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="validationCustom01">
                            Final Amount <span className="red_show">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control trio_mandatory length_min"
                            name="final_amount_set"
                            readOnly
                            id="final_amount_set"
                            maxLength={10}
                            defaultValue={finaltotalAmount}
                            placeholder="Enter Final Amount"
                          />
                          <span className="condition_error"></span>
                        </div>
                        <div className="col-md-3 mb-3 hidden">
                          <label htmlFor="validationCustom01">
                            Final Amount <span className="red_show">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control trio_mandatory length_min"
                            name="final_amount"
                            id="final_amount"
                            maxLength={10}
                            defaultValue={finaltotalAmount}
                            placeholder="Enter Final Amount"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="validationCustom01">
                            Advance Amount <span className="red_show">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control trio_mandatory length_min"
                            name="lead_advance_amount"
                            id="lead_advance_amount"
                            onBlur={handleBlur}
                            maxLength={10}
                            onInput={handleNumbersDecimalChange}
                            placeholder="Enter Advance Amount"
                            defaultValue={advanceAmount}
                            onChange={handleAdvanceAmountChange}
                          />
                          <span
                            style={{ fontWeight: "400", fontSize: "14px" }}
                            className="condition_error"
                          >
                            {error}
                          </span>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="validationCustom01">
                            Referred Person Name
                          </label>
                          <input
                            type="text"
                            className="form-control  "
                            name="referred_by_person_name"
                            id="referred_by_person_name"
                            maxLength={100}
                            onInput={handleAphabetsChange}
                            placeholder="Enter Referred Person Name"
                            defaultValue={
                              editBlogData.referred_by_person_name || ""
                            }
                          />
                          <span className="condition_error"></span>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="validationCustom01">
                            Referred Mobile No
                          </label>
                          <input
                            type="text"
                            className="form-control  trio_no"
                            name="referred_by_person_no"
                            id="referred_by_person_no"
                            maxLength={10}
                            onInput={handleNumbersChange}
                            placeholder="Enter Mobile No"
                            defaultValue={
                              editBlogData.referred_by_person_no || ""
                            }
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="validationCustom01">
                            Special Requirement
                          </label>

                          <textarea
                            type="text"
                            className="form-control  "
                            name="special_requirement"
                            id="special_requirement"
                            maxLength={400}
                            onInput={handleIaphabetnumberChange}
                            placeholder="Enter Special Requirement"
                            defaultValue={
                              editBlogData.special_requirement || ""
                            }
                          ></textarea>
                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_update_main_lead
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default EnquiryDashboardAdd;
