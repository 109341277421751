import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  handleLinkClick,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  get_userright_data,
} from "../../ServiceConnection/serviceconnection.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function AdminSidebar() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const retrievedAdminId = retrieveData("staff_id");
  const retrievedAdminProfession = retrieveData("admin_profession");
  const [editBlogData, seteditBlogData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_userright_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message.seo_loop);
          seteditBlogData(Response.data.message.seo_loop);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    if (parts.length !== 1) {
      const start_date = "";
      const end_date = "";
      const flag = "1";
      const call_id = parts[1];
      master_data_get(start_date, end_date, flag, call_id);
    }
  }, []);

  const match_and_return_seo_link = (data_seo_link_final) => {
    let data_seo_link = false;
    if (editBlogData) {
      const matchedItem = editBlogData.find(
        (data) => data_seo_link_final === data.fun_url
      );

      if (matchedItem) {
        data_seo_link = true;
      }
    }

    return data_seo_link;
  };

  return (
    <div className="app-sidebar sidebar-shadow">
      <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
          <div>
            <button
              type="button"
              class="hamburger close-sidebar-btn hamburger--elastic"
              data-class="closed-sidebar"
            >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="app-header__mobile-menu">
        <div>
          <button
            type="button"
            class="hamburger hamburger--elastic mobile-toggle-nav"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div class="app-header__menu">
        <span>
          <button
            type="button"
            class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
          >
            <span class="btn-icon-wrapper">
              <i class="fa fa-ellipsis-v fa-w-6"></i>
            </span>
          </button>
        </span>
      </div>
      <div class="scrollbar-sidebar ps ps--active-y">
        <div class="app-sidebar__inner">
          <ul class="vertical-nav-menu metismenu">
            <li className="app-sidebar__heading ">Dashboard</li>
            <li>
              <Link
                onClick={() => handleLinkClick("/Admindashboard")}
                className={
                  currentUrl.toLowerCase() === "/Admindashboard".toLowerCase()
                    ? "mm-active"
                    : ""
                }
              >
                <i className="metismenu-icon fa fa-home"></i>
                Dashboard
              </Link>
            </li>
            <li className="app-sidebar__heading">Enquiry/Booking</li>

            {retrievedAdminProfession === "0" &&
              match_and_return_seo_link("enquiryDashboardView") && (
                <li>
                  <Link
                    onClick={() => handleLinkClick("/enquiryDashboardView")}
                    className={
                      currentUrl.toLowerCase() ===
                        "/enquiryDashboardView".toLowerCase() ||
                      currentUrl.toLowerCase() ===
                        "/enquiryDashboardAdd".toLowerCase() ||
                      currentUrl
                        .toLowerCase()
                        .includes("/enquiryDashboardDetails".toLowerCase()) ||
                      currentUrl
                        .toLowerCase()
                        .includes("/enquiryDashboardViewDetails".toLowerCase())
                        ? "mm-active"
                        : ""
                    }
                  >
                    <i className="metismenu-icon fa fa-eye "></i>
                    View Enquiry
                  </Link>
                </li>
              )}
            {match_and_return_seo_link("enquiryAssignDashboardView") && (
              <li>
                <Link
                  onClick={() => handleLinkClick("/enquiryAssignDashboardView")}
                  className={
                    currentUrl.toLowerCase() ===
                      "/enquiryAssignDashboardView".toLowerCase() ||
                    currentUrl
                      .toLowerCase()
                      .includes("/EnquiryDashboardConfirm".toLowerCase()) ||
                    currentUrl
                      .toLowerCase()
                      .includes("/enquiryDashboardAgain".toLowerCase())
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="metismenu-icon fa fa-hand-o-right "></i>
                  Assign Enquiry
                </Link>
              </li>
            )}
            {match_and_return_seo_link("enquiryCompleteDashboardView") && (
              <li>
                <Link
                  onClick={() =>
                    handleLinkClick("/enquiryCompleteDashboardView")
                  }
                  className={
                    currentUrl.toLowerCase() ===
                      "/enquiryCompleteDashboardView".toLowerCase() ||
                    currentUrl
                      .toLowerCase()
                      .includes(
                        "/enquiryCompleteDashboardDetails".toLowerCase()
                      )
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="metismenu-icon fa fa-check-square-o "></i>
                  Complete Enquiry
                </Link>
              </li>
            )}
            {match_and_return_seo_link("CustomerBookingDashboardView") && (
              <li>
                <Link
                  onClick={() =>
                    handleLinkClick("/CustomerBookingDashboardView")
                  }
                  className={
                    currentUrl.toLowerCase() ===
                      "/CustomerBookingDashboardView".toLowerCase() ||
                    currentUrl
                      .toLowerCase()
                      .includes(
                        "/CustomerBookingDashboardDetails".toLowerCase()
                      ) ||
                    currentUrl
                      .toLowerCase()
                      .includes("/CustomerBookingInvoice".toLowerCase())
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="metismenu-icon fa fa-book"></i>
                  Confirm Booking
                </Link>
              </li>
            )}
            <li className="app-sidebar__heading">Website Information</li>
            {match_and_return_seo_link("staffDashboardView") && (
              <li>
                <Link
                  onClick={() => handleLinkClick("/staffDashboardView")}
                  className={
                    currentUrl.toLowerCase() ===
                      "/staffDashboardView".toLowerCase() ||
                    currentUrl.toLowerCase() ===
                      "/staffDashboardAdd".toLowerCase() ||
                    currentUrl
                      .toLowerCase()
                      .includes("/staffDashboardEdit".toLowerCase()) ||
                    currentUrl
                      .toLowerCase()
                      .includes("/UserMasterStaffDashboardEdit".toLowerCase())
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="metismenu-icon fa fa-users "></i>
                  Staff Management
                </Link>
              </li>
            )}
            {match_and_return_seo_link("FeedbackDashboardView") && (
              <li>
                <Link
                  onClick={() => handleLinkClick("/FeedbackDashboardView")}
                  className={
                    currentUrl.toLowerCase() ===
                    "/FeedbackDashboardView".toLowerCase()
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="metismenu-icon fa fa-tachometer "></i>
                  Feedback Management
                </Link>
              </li>
            )}
            {match_and_return_seo_link("leaddashboardview") && (
              <li>
                <Link
                  onClick={() => handleLinkClick("/leaddashboardview")}
                  className={
                    currentUrl.toLowerCase() ===
                    "/leaddashboardview".toLowerCase()
                      ? "mm-active"
                      : ""
                  }
                >
                  <i className="metismenu-icon fa fa-bullhorn "></i>
                  JD Lead Management
                </Link>
              </li>
            )}

            <li
              className={
                currentUrl.toLowerCase() ===
                  "/websiteDashboardAdd".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/gallerywebsiteDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/gallerywebsiteDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/gallerywebsiteDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/eventlistDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/eventlistDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/eventlistDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/eventTimingDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/eventTimingDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/eventTimingDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/landmarkDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/landmarkDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/landmarkDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/sliderDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/sliderDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/sliderDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/teamDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/teamDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/TeamDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/TestimonialDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/TestimonialDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/TestimonialDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/imageDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() ===
                  "/ImageDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/BlogDashboardEdit".toLowerCase()) ||
                currentUrl.toLowerCase() ===
                  "/SeoDashboardView".toLowerCase() ||
                currentUrl.toLowerCase() === "/SeoDashboardAdd".toLowerCase() ||
                currentUrl
                  .toLowerCase()
                  .includes("/SeoDashboardEdit".toLowerCase())
                  ? "mm-active"
                  : ""
              }
            >
              <a href="#" aria-expanded="false">
                <i className="metismenu-icon fa fa-laptop "></i>
                Website Management&nbsp;&nbsp;
                <i class="metismenu-state-icon fa fa-angle-down caret-left"></i>
              </a>
              <ul
                className={
                  currentUrl.toLowerCase() ===
                    "/websiteDashboardAdd".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/gallerywebsiteDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/gallerywebsiteDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/gallerywebsiteDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/eventlistDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/eventlistDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/eventlistDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/eventTimingDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/eventTimingDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/eventTimingDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/landmarkDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/landmarkDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/landmarkDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/sliderDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/sliderDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/sliderDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/teamDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/teamDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/TeamDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/TestimonialDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/TestimonialDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/TestimonialDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/imageDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/ImageDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/BlogDashboardEdit".toLowerCase()) ||
                  currentUrl.toLowerCase() ===
                    "/SeoDashboardView".toLowerCase() ||
                  currentUrl.toLowerCase() ===
                    "/SeoDashboardAdd".toLowerCase() ||
                  currentUrl
                    .toLowerCase()
                    .includes("/SeoDashboardEdit".toLowerCase())
                    ? "mm-collapse mm-show"
                    : "mm-collapse "
                }
              >
                {match_and_return_seo_link("websiteDashboardAdd") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/websiteDashboardAdd")}
                      className={
                        currentUrl.toLowerCase() ===
                        "/websiteDashboardAdd".toLowerCase()
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i className="metismenu-icon fa fa-laptop "></i>
                      <i class="metismenu-icon"></i>Website Info
                    </Link>
                  </li>
                )}

                {match_and_return_seo_link("gallerywebsiteDashboardView") && (
                  <li>
                    <Link
                      onClick={() =>
                        handleLinkClick("/gallerywebsiteDashboardView")
                      }
                      className={
                        currentUrl.toLowerCase() ===
                          "/gallerywebsiteDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/gallerywebsiteDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes(
                            "/gallerywebsiteDashboardEdit".toLowerCase()
                          )
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Gallery
                    </Link>
                  </li>
                )}
                {match_and_return_seo_link("sliderDashboardView") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/sliderDashboardView")}
                      className={
                        currentUrl.toLowerCase() ===
                          "/sliderDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/sliderDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/sliderDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Slider
                    </Link>
                  </li>
                )}
                {match_and_return_seo_link("imageDashboardView") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/imageDashboardView")}
                      className={
                        currentUrl.toLowerCase() ===
                          "/imageDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/ImageDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/BlogDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Image Links
                    </Link>
                  </li>
                )}
                {match_and_return_seo_link("eventlistDashboardView") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/eventlistDashboardView")}
                      className={
                        currentUrl.toLowerCase() ===
                          "/eventlistDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/eventlistDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/eventlistDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Event List
                    </Link>
                  </li>
                )}
                {match_and_return_seo_link("eventTimingDashboardView") && (
                  <li>
                    <Link
                      onClick={() =>
                        handleLinkClick("/eventTimingDashboardView")
                      }
                      className={
                        currentUrl.toLowerCase() ===
                          "/eventTimingDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/eventTimingDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/eventTimingDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Event Timing
                    </Link>
                  </li>
                )}

                {match_and_return_seo_link("landmarkDashboardView") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/landmarkDashboardView")}
                      className={
                        currentUrl.toLowerCase() ===
                          "/landmarkDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/landmarkDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/landmarkDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Near By
                    </Link>
                  </li>
                )}

                {match_and_return_seo_link("TestimonialDashboardView") && (
                  <li>
                    <Link
                      onClick={() =>
                        handleLinkClick("/TestimonialDashboardView")
                      }
                      className={
                        currentUrl.toLowerCase() ===
                          "/TestimonialDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/TestimonialDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/TestimonialDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Testimonials
                    </Link>
                  </li>
                )}

                {match_and_return_seo_link("teamDashboardView") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/teamDashboardView")}
                      className={
                        currentUrl.toLowerCase() ===
                          "/teamDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/teamDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/TeamDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Team
                    </Link>
                  </li>
                )}

                {match_and_return_seo_link("BlogDashboardView") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/BlogDashboardView")}
                      className={
                        currentUrl.toLowerCase() ===
                          "/BlogDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/BlogDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/BlogDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Blogs
                    </Link>
                  </li>
                )}
                {match_and_return_seo_link("SeoDashboardView") && (
                  <li>
                    <Link
                      onClick={() => handleLinkClick("/SeoDashboardView")}
                      className={
                        currentUrl.toLowerCase() ===
                          "/SeoDashboardView".toLowerCase() ||
                        currentUrl.toLowerCase() ===
                          "/SeoDashboardAdd".toLowerCase() ||
                        currentUrl
                          .toLowerCase()
                          .includes("/SeoDashboardEdit".toLowerCase())
                          ? "mm-active"
                          : ""
                      }
                    >
                      <i class="metismenu-icon"></i>
                      Seo Section
                    </Link>
                  </li>
                )}
              </ul>
            </li>
            {match_and_return_seo_link("menuDashboardView") && (
              <li>
                <Link
                  onClick={() => handleLinkClick("/menuDashboardView")}
                  className={
                    currentUrl.toLowerCase() ===
                      "/menuDashboardView".toLowerCase() ||
                    currentUrl.toLowerCase() ===
                      "/menuDashboardAdd".toLowerCase() ||
                    currentUrl
                      .toLowerCase()
                      .includes("/menuDashboardEdit".toLowerCase())
                      ? "mm-active"
                      : ""
                  }
                >
                  <i class="metismenu-icon fa fa-bars "></i>Menu Management
                </Link>
              </li>
            )}
            {match_and_return_seo_link("hallDashboardView") && (
              <li>
                <Link
                  onClick={() => handleLinkClick("/hallDashboardView")}
                  className={
                    currentUrl.toLowerCase() ===
                      "/hallDashboardView".toLowerCase() ||
                    currentUrl.toLowerCase() ===
                      "/hallDashboardAdd".toLowerCase() ||
                    currentUrl
                      .toLowerCase()
                      .includes("/hallDashboardEdit".toLowerCase())
                      ? "mm-active"
                      : ""
                  }
                >
                  <i class="metismenu-icon fa fa-university "></i>
                  Hall
                </Link>
              </li>
            )}
            {match_and_return_seo_link("subscribeDashboardView") && (
              <li>
                <Link
                  onClick={() => handleLinkClick("/subscribeDashboardView")}
                  className={
                    currentUrl.toLowerCase() ===
                    "/subscribeDashboardView".toLowerCase()
                      ? "mm-active"
                      : ""
                  }
                >
                  <i class="metismenu-icon fa fa-check-square-o "></i>
                  Subscribers
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AdminSidebar;
