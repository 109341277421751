import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  save_update_main_lead,
  get_all_main_lead,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleNumbersChange,
  computeTodayDate,
  handleDateChange,
  computeFutureDate,
  handleNumbersDecimalChange,
  handleSuccessSession,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function EnquiryDashboardDetails() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [editBlogData, seteditBlogData] = useState([]);
  const [HalllistData, seteditHalllistData] = useState([]);
  const [MenulistData, setMenulistData] = useState([]);
  const [EventTiminglistData, setEventTiminglistData] = useState([]);
  const [StafflistData, setStafflistData] = useState([]);
  const [MenulistSelectedData, setMenulistSelectedData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [error, setError] = useState("");
  const retrievedAdminId = retrieveData("staff_id");
  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }

    const input = document.getElementById("searchInput");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ["(cities)"], // Restrict results to cities
    });

    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();
      let full_address = place.address_components;
      let length_data = place.address_components.length;
      let citys = "";
      let state = "";
      let country = "";
      let tehsil = "";

      for (let i = 0; i < length_data; i++) {
        if (full_address[i].types[0] === "administrative_area_level_1") {
          state = full_address[i].long_name;
        } else if (full_address[i].types[0] === "country") {
          country = full_address[i].long_name;
        } else if (full_address[i].types[0] === "administrative_area_level_2") {
          citys = full_address[i].long_name;
        } else if (full_address[i].types[0] === "locality") {
          tehsil = full_address[i].long_name;
        }
      }
      if (tehsil !== "") {
        citys = tehsil;
      }
      document.getElementById("lead_person_city").value = citys;
      document.getElementById("lead_person_state").value = state;
      document.getElementById("lead_person_country").value = country;
    });

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("select_resource", 'All');
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_main_lead, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_main_lead.length > 0) {
            seteditBlogData(Response.data.message.data_main_lead[0]);
            setEditorDatMainID(
              Response.data.message.data_main_lead[0].primary_id
            );
            setTotalAmount(
              Response.data.message.data_main_lead[0].lead_total_amount
            );
            setAdvanceAmount(
              Response.data.message.data_main_lead[0].lead_advance_amount
            );
          }

          seteditHalllistData(Response.data.message.data_halllist);
          setMenulistData(Response.data.message.data_menulist);
          setMenulistSelectedData(Response.data.message.data_menuselectlist);
          setEventTiminglistData(Response.data.message.data_eventtiming);
          setStafflistData(Response.data.message.data_admin_staff);
          console.log(Response.data.message.data_menuselectlist);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("lead_source", editBlogData.lead_source);
      fd_from.append("lead_assign_by_staff", retrievedAdminId);
      fd_from.append("status_for_lead", "1");
      fd_from.append("main_id", editorDataMainID);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(
              Response.data.message,
              "/enquiryAssignDashboardView"
            );
            if (editorDataMainID === "0") {
              empty_form(form_data);
            }
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleMenuChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const selectedMenuDetails = selectedOptions.map((option) =>
      option.value.split("~@~")
    );
    // Calculate total amount based on selected menu prices
    const newTotalAmount = selectedMenuDetails.reduce(
      (total, [, , menuPrice, menuDiscount]) => {
        let price = parseFloat(menuPrice);
        if (menuDiscount > 0) {
          price = parseFloat(menuDiscount);
        }
        return total + price;
      },
      0
    );

    setTotalAmount(newTotalAmount);
  };

  const handleBlur = () => {
    const totalAmountValue = parseFloat(totalAmount);
    const advanceAmountValue = parseFloat(advanceAmount);
    console.log(totalAmountValue > advanceAmountValue);
    if (
      !isNaN(totalAmountValue) &&
      !isNaN(advanceAmountValue) &&
      totalAmountValue > advanceAmountValue
    ) {
      setAdvanceAmount("0");
    }
  };

  function handleAdvanceAmountChange(event) {
    const enteredAdvanceAmount = parseFloat(event.target.value);
    const totalAmount = parseFloat(
      document.getElementById("lead_total_amount").value
    );
    if (enteredAdvanceAmount > totalAmount) {
      event.target.value = "";
      setError("Advance amount cannot be greater than total amount");
    } else {
      setAdvanceAmount(enteredAdvanceAmount);
      setError("");
    }
  }

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Enquiry Dashboard
                    <div className="page-title-subheading">
                      Assign Staff and Edit Enquiry
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Assign Staff and Edit Enquiry</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Assign To<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_assign_to_staff"
                        id="lead_assign_to_staff"
                      >
                        {StafflistData.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={blogdddddd.primary_id}
                            selected={
                              editBlogData.lead_assign_to_staff ===
                              blogdddddd.primary_id
                            }
                          >
                            {blogdddddd.admin_name}({" "}
                            {blogdddddd.admin_mobile_no})
                          </option>
                        ))}
                      </select>

                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Enquiry Start Date<span className="red_show">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control  trio_mandatory"
                        name="lead_start_date"
                        id="lead_start_date"
                        min={computeTodayDate()} // Replace with the maximum date you want to allow
                        max={computeFutureDate()} // Replace with the maximum date you want to allow
                        placeholder="Enter Enquiry Start Date"
                        defaultValue={
                          editBlogData.lead_start_date || computeTodayDate()
                        }
                        onChange={handleDateChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Hall Name<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_for_event_hall"
                        id="lead_for_event_hall"
                      >
                        {HalllistData.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={blogdddddd.hall_name}
                            selected={
                              editBlogData.lead_for_event_hall ===
                              blogdddddd.hall_name
                            }
                          >
                            {blogdddddd.hall_name}
                          </option>
                        ))}
                      </select>

                      <span className="condition_error"></span>
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Enquiry Type<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_status"
                        id="lead_status"
                      >
                        <option
                          value="Hot"
                          selected={editBlogData.lead_status === "Hot"}
                        >
                          Hot
                        </option>
                        <option
                          value="Cold"
                          selected={editBlogData.lead_status === "Cold"}
                        >
                          Cold
                        </option>
                        <option
                          value="Warm"
                          selected={editBlogData.lead_status === "Warm"}
                        >
                          Warm
                        </option>
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        Booking Type<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_type"
                        id="lead_type"
                      >
                        <option
                          value="Enquiry"
                          selected={editBlogData.lead_type === "Enquiry"}
                        >
                          Enquiry
                        </option>
                        <option
                          value="Booking"
                          selected={editBlogData.lead_type === "Booking"}
                        >
                          Booking
                        </option>
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Person Name<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lead_person_name"
                        id="lead_person_name"
                        maxLength={35}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Person Name"
                        defaultValue={editBlogData.lead_person_name || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">Company Name</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_company_name"
                        id="lead_person_company_name"
                        maxLength={50}
                        onInput={handleAphabetsChange}
                        placeholder="Enter  Company Name"
                        defaultValue={
                          editBlogData.lead_person_company_name || ""
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Mobile No<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory trio_no"
                        name="lead_person_mobile_no"
                        id="lead_person_mobile_no"
                        maxLength={10}
                        onInput={handleNumbersChange}
                        placeholder="Enter Mobile No"
                        defaultValue={editBlogData.lead_person_mobile_no || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="validationCustom01"> Email ID</label>
                      <input
                        type="text"
                        className="form-control "
                        name="lead_person_email_id"
                        id="lead_person_email_id"
                        maxLength={60}
                        onInput={handleEmailChange}
                        placeholder="Enter Email"
                        defaultValue={editBlogData.lead_person_email_id || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-8 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Address<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="lead_person_address"
                        id="lead_person_address"
                        maxLength={90}
                        onInput={handleIaphabetnumberChange}
                        placeholder="Enter Address"
                        defaultValue={editBlogData.lead_person_address || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        {" "}
                        Search City<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="searchInput"
                        id="searchInput"
                        maxLength={200}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City Name"
                        defaultValue={editBlogData.lead_person_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row hidden">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">City</label>
                      <input
                        type="text"
                        className="form-control  searchInput_google"
                        name="lead_person_city"
                        id="lead_person_city"
                        maxLength={45}
                        onInput={handleAphabetsChange}
                        placeholder="Enter City"
                        defaultValue={editBlogData.lead_person_city || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01"> State</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_state"
                        id="lead_person_state"
                        maxLength={45}
                        onInput={handleAphabetsChange}
                        placeholder="Enter State"
                        defaultValue={editBlogData.lead_person_state || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">Country</label>
                      <input
                        type="text"
                        className="form-control  "
                        name="lead_person_country"
                        id="lead_person_country"
                        maxLength={45}
                        onInput={handleAphabetsChange}
                        placeholder="Enter Country"
                        defaultValue={
                          editBlogData.lead_person_country || "India"
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Date<span className="red_show">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control  trio_mandatory"
                        name="lead_event_date"
                        id="lead_event_date"
                        min={computeTodayDate()} // Replace with the maximum date you want to allow
                        max={computeFutureDate()} // Replace with the maximum date you want to allow
                        placeholder="Enter Event date"
                        onChange={handleDateChange}
                        defaultValue={editBlogData.lead_event_date || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        Event Timing<span className="red_show">*</span>
                      </label>
                      <select
                        className="form-control trio_mandatory"
                        name="lead_for_eventtiming_full"
                        id="lead_for_eventtiming_full"
                      >
                        {EventTiminglistData.map((blogdddddd, index) => (
                          <option
                            key={index}
                            value={
                              blogdddddd.primary_id +
                              "~@~" +
                              blogdddddd.timing_name
                            }
                            selected={
                              editBlogData.lead_for_eventtiming_id ===
                              blogdddddd.primary_id
                            }
                          >
                            {blogdddddd.timing_name}({" "}
                            {blogdddddd.timing_from +
                              "-" +
                              blogdddddd.timing_to}
                            )
                          </option>
                        ))}
                      </select>

                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="validationCustom01">
                        No of Guest<span className="red_show">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory length_min"
                        name="lead_event_no_of_person_come"
                        id="lead_event_no_of_person_come"
                        maxLength={5}
                        onInput={handleNumbersChange}
                        placeholder="Enter No of Guest"
                        defaultValue={
                          editBlogData.lead_event_no_of_person_come || ""
                        }
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_update_main_lead
                      )
                    }
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default EnquiryDashboardDetails;
