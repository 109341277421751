import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Css/AboutUs.css";
import RightW from "../../Assests/images/rightWhite.svg";
import Prev from "../../Assests/images/arrow7.svg";
import Next from "../../Assests/images/arrow6.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AboutImg1 from "../../Assests/images/about1.png";
import AboutImg2 from "../../Assests/images/about2.png";
import TeamImg from "../../Assests/images/team1.png";
import $ from "jquery";
import {
  APL_LINK,
  server_post_data,
  get_all_home_about,
} from "../../ServiceConnection/serviceconnection.js";
function AboutUs() {
  const options = {
    loop: true,
    margin: false,
    nav: false,
    items: 1,
    dots: false,
    autoplay: true,
  };

  const sliderRef1 = useRef(null);

  const handleNextClick = () => {
    // Access the Owl Carousel instance and trigger the next slide
    sliderRef1.current.next();
  };

  const handlePrevClick = () => {
    // Access the Owl Carousel instance and trigger the previous slide
    sliderRef1.current.prev();
  };

  useEffect(() => {
    // Event delegation to handle dynamic content
    $(document).on("click", ".custom-next", handleNextClick);
    $(document).on("click", ".custom-prev", handlePrevClick);

    // Cleanup the event listeners when the component unmounts
    return () => {
      $(document).off("click", ".custom-next", handleNextClick);
      $(document).off("click", ".custom-prev", handlePrevClick);
    };
  }, []);

  useEffect(() => {
    // Custom Navigation for First Carousel
    $(".custom-next").click(function () {
      $(".owl-carousel.owl-carouselHall").trigger("next.owl.carousel");
    });

    $(".custom-prev").click(function () {
      $(".owl-carousel.owl-carouselHall").trigger("prev.owl.carousel");
    });

    $(".custom-next").click(function () {
      $(".owl-theme2").trigger("next.owl.carousel");
    });

    $(".custom-prev").click(function () {
      $(".owl-theme2").trigger("prev.owl.carousel");
    });

    $(".custom-next").click(function () {
      $(".owl-theme3").trigger("next.owl.carousel");
    });

    $(".custom-prev").click(function () {
      $(".owl-theme3").trigger("prev.owl.carousel");
    });
  }, []);

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [categories, setcategories] = useState([]);
  const [data_team, setdata_team] = useState([]);
  const [imagedata, setimagedata] = useState([]);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", "0");
    await server_post_data(get_all_home_about, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message);

          const sliderImageData = [];
          Response.data.message.data_silder.find((itessss) => {
            sliderImageData.push({
              image_link:
                APL_LINK +
                Response.data.message.data_silder_image +
                itessss.silder_image_name,
            });
          });
          setcategories(sliderImageData);
          setdata_team(Response.data.message.data_team);
          setimagedata(Response.data.message.data_silder_image);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "3";
    const call_id = "0";

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const weddingText =
    "Our wedding hall promises enchanting moments, great service along with a touch of grandeur and elegance. With a blend of sophistication and tradition, we create unforgettable experiences, making your day truly extraordinary!";

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded) {
      return weddingText;
    } else {
      return truncateText(weddingText, 14);
    }
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    return words.slice(0, lines).join(" ") + "...";
  };

  return (
    <div className="about">
      <Header />
      <div className="aboutContainer">
        <section className="aboutFront">
          <div className="aboutFront">
            <div className="row rowReverse m-0 h-100">
              <div className="col-sm-5 h-100">
                <div className="col-xl-9 col-lg-11 m-auto aboutFrontTexrt">
                  <div className="aboutFrontTexrtContainer">
                    <h2 data-aos="fade-right" data-aos-delay="100">
                      Raghuvanshi Hall: Timeless Haven for celebrating your love
                      story!
                    </h2>
                    <p data-aos="fade-right" data-aos-delay="200">
                      {renderText()}
                    </p>
                    <div
                      className="readmoreBtn"
                      data-aos="fade-right"
                      data-aos-delay="300"
                    >
                      <button onClick={toggleText}>
                        <p>{isExpanded ? "Read Less" : "Read More"}</p>
                        <img
                          src={RightW}
                          alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                          title="Raghuvanshi Hall"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-7 h-100 p-0 d-flex flex-column">
                <div className="aboutFrontSlider">
                  {categories.length > 0 && (
                    <OwlCarousel
                      className="owl-theme3"
                      {...options}
                      ref={sliderRef1}
                    >
                      {categories.map((item, index) => (
                        <div className="" key={index}>
                          <img
                            src={item.image_link}
                            alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                          />
                        </div>
                      ))}
                    </OwlCarousel>
                  )}

                  <div className="aboutcustom-nav">
                    <button className="custom-prev">
                      <img
                        src={Prev}
                        alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                      />
                    </button>
                    <div className="aboutcustom-saperation"></div>
                    <button className="custom-next">
                      <img
                        src={Next}
                        alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="raghuHall container-lg">
          <div className="raghuHallContainer">
            <div className="raghuHallAboutCArd">
              <div className="row m-0">
                <div className="col-sm-6">
                  <div className="aghuHallAboutText">
                    <div>
                      <h5>About Raghuvanshi hall</h5>
                      <p>
                        For the organisation of a perfect wedding, the selection
                        of the correct venue is extremely crucial. If you are
                        seeking a venue that’s ideal to host your wedding
                        functions, then Raghuvanshi Hall can be considered an
                        excellent choice for you. Based in the Thane region of
                        Mumbai, it is one of the best wedding halls in Thane and
                        we are well equipped and offer great facilities to make
                        your wedding exquisite which is worth remembering for a
                        lifetime. With an expert team of staff, you can be
                        assured that your event will be one-of-a-kind and is
                        also accessible easily from any part of the city.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="aghuHallAboutImg"
                    data-aos="fade-left"
                    data-aos-easing="ease-in-sine"
                  >
                    <img
                      src={AboutImg1}
                      alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="raghuHallAboutCArd mt-5">
              <div className="row reverse m-0">
                <div className="col-sm-6">
                  <div className="aghuHallAboutText">
                    <div>
                      <h5>Other services</h5>
                      <p>
                        Ranging from pre-wedding to post-wedding functions, you
                        need various setups for each of the ceremonies. The
                        venue has all types of setups available and can easily
                        accommodate 650 guests at a time. In addition to that,
                        we have various facilities available such as valet
                        parking, electricity with backup, basic lighting and
                        furniture as per your convenience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="aghuHallAboutImg"
                    data-aos="fade-right"
                    data-aos-easing="ease-in-sine"
                  >
                    <img
                      src={AboutImg2}
                      alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="outeam">
          <div className="outeamContaienr container-lg m-auto">
            <h3>Our Team</h3>
            <div className="teamCardsCOntainer">
              <div className="row m-o">
                {data_team.map((item, index) => (
                  <div className="col-sm-3 col-6" key={index}>
                    <div
                      className="teamCard"
                      data-aos="flip-up"
                      data-aos-delay="100"
                      data-aos-easing="ease-in-sine"
                    >
                      <img
                        src={APL_LINK + imagedata + item.team_image}
                        alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                      />
                      <div className="teamCardTxt">
                        <h6>{item.team_name}</h6>
                        <p>{item.team_position}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
