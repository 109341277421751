import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import {
  server_post_data,
  Website_URL,
  save_update_usermaster,
  get_all_usermaster,
} from "../../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAlphabetsWithoutSpaceChange,
  handleSuccessSession,
  handleError,
  handleAphabetsChange,
} from "../../CommonJquery/CommonJquery.js";
import { useLocation, Link } from "react-router-dom";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
function UserMasterDashboardAdd() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editBlogData, seteditBlogData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const retrievedAdminId = retrieveData("staff_id");

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    if (parts.length !== 1) {
      const start_date = "";
      const end_date = "";
      const flag = "3";
      const call_id = parts[1];
      master_data_get(start_date, end_date, flag, call_id);
    }
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_usermaster, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditBlogData(Response.data.message.data_master[0]);
          setEditorDatMainID(Response.data.message.data_master[0].rights_id);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("admin_id", retrievedAdminId);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("website_url", Website_URL);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(
              Response.data.message,
              "/UsermasterDashboardView"
            );
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    User Master Dashboard
                    <div className="page-title-subheading">
                      Add and Edit User Master
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-card mb-3 card">
              <div className="card-body">
                <h5 className="card-title">Add/Edit User Master</h5>
                <form className="needs-validation" id="CarrerformData">
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">Master Name<span className="red_show">*</span></label>
                      <div>
                        <input
                          type="text"
                          className="form-control  trio_mandatory"
                          name="master_name"
                          id="master_name"
                          maxLength={100}
                          onInput={handleAphabetsChange}
                          placeholder="Enter Name"
                          defaultValue={editBlogData.master_name || ""}
                        />
                      </div>
                      <span className="condition_error"></span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom01">Function Name<span className="red_show">*</span></label>
                      <div>
                        <input
                          type="text"
                          className="form-control  trio_mandatory"
                          name="function_name"
                          id="function_name"
                          maxLength={100}
                          onInput={handleAphabetsChange}
                          placeholder="Enter Function Name"
                          defaultValue={editBlogData.function_name || ""}
                        />
                      </div>

                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom01">Function Url<span className="red_show">*</span></label>
                      <input
                        type="text"
                        className="form-control  trio_mandatory"
                        name="function_url"
                        id="function_url"
                        maxLength={100}
                        placeholder="Enter URl"
                        defaultValue={editBlogData.function_url || ""}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "CarrerformData",
                        save_update_usermaster
                      )
                    }
                  >
                    Submit 
                  </button>
                </form>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}
export default UserMasterDashboardAdd;
