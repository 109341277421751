import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.js";
import AdminFooter from "./AdminFooter.js";
import AdminHeader from "./AdminHeader.js";
import LeadDash from "../../Css/LeadDash.css";
import {
  server_post_data,
  get_all_leads,
} from "../../ServiceConnection/serviceconnection.js";
import {
  fnExcelReport,
  handleLinkClick,
  handleConfimDeleteClick,
  formatDateString,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { retrieveData } from "../../LocalConnection/LocalConnection.js";
import { Link } from "react-router-dom";
function LeadDashboardView() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [BlogData, setsBlogData] = useState([]);
  const retrievedAdminId = retrieveData("staff_id");

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];
  const [SingleBlogData, setsSingleBlogData] = useState([]);
  // Initialize end date as today
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(formattedSevenDaysAgo);
  const [endDate, setEndDate] = useState(formattedToday);
  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "1";
    const call_id = "0";
    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", retrievedAdminId);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_leads, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsBlogData(Response.data.message.data_blog);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-header">
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <AdminHeader />

      <div className="app-main">
        <AdminSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <Link to="/admindashboard">
                    <div className="page-title-icon">
                      <i className="fa fa-home icon-gradient bg-mean-fruit"></i>
                    </div>
                  </Link>
                  <div>
                    Lead Management &gt; Lead Section
                    <div className="page-title-subheading">
                      Where You Can Check Lead
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12">
                <div className="main-card mb-3 card">
                  <div className="card-header card-header-view">
                    <div className="btn-actions-pane-left row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>Start Date</b>
                          </label>
                          <input
                            type="date"
                            className="form-control trio_search_ajax trio_mandatory_view"
                            name="start_date"
                            id="start_date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            <b>End Date</b>
                          </label>
                          <input
                            type="date"
                            className="form-control trio_search_ajax trio_mandatory_view"
                            name="end_date"
                            id="end_date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-1">
                        <div className="form-group">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              <b>&nbsp;</b>
                            </label>
                            <button
                              type="button"
                              className="form-control  btn btn-primary btn-sm"
                              name="end_date"
                              onClick={() => search_data()}
                            >
                              <i className="ace-icon fa fa-search icon-on-right bigger-110"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="btn-actions-pane-right btn-actions-pane-right-float ">
                        <div role="group" className="btn-group-sm btn-group">
                          <button
                            className="active btn btn-focus"
                            onClick={fnExcelReport}
                          >
                            Download Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="OvrFlow">
                    <table
                      className="align-middle mb-0 table table-borderless table-striped table-hover"
                      id="dynamic-table1"
                    >
                      <thead>
                        <tr>
                          <th className="text-center">S.No</th>
                          <th className="text-center">Entry Date</th>
                          <th className="text-center">Details</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Mobile</th>
                          <th className="text-center">Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BlogData.map((blogddd, index) => (
                          <tr key={index}>
                            <td className="text-center text-muted">
                              {index + 1}
                            </td>

                            <td className="text-center">
                              {formatDateString(blogddd.entry_date)}
                            </td>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn mr-2 mb-2 btn-primary table-detail"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setsSingleBlogData(blogddd)}
                              >
                                See More
                              </button>
                            </td>
                            <td className="text-center">
                              {blogddd.prefix} {blogddd.name}
                            </td>
                            <td className="text-center">{blogddd.mobile}</td>
                            <td className="text-center">{blogddd.phone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <AdminFooter />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {SingleBlogData.prefix} {SingleBlogData.name}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="mb-0">
                <b>Enquiry ID</b>:- <span>{SingleBlogData.leadid}</span>
              </p>
              <p className="mb-0">
                <b>Enquiry Type</b>:- <span>{SingleBlogData.leadtype}</span>
              </p>
              <p className="mb-0">
                <b>Name</b>:-{" "}
                <span>
                  {SingleBlogData.prefix} {SingleBlogData.name}
                </span>
              </p>
              <p className="mb-0">
                <b>Mobile</b>:- <span>{SingleBlogData.mobile}</span>
              </p>
              <p className="mb-0">
                <b>Phone</b>:- <span>{SingleBlogData.phone}</span>
              </p>
              <p className="mb-0">
                <b>Email</b>:- <span>{SingleBlogData.email}</span>
              </p>
              <p className="mb-0">
                <b>Date</b>:- <span>{SingleBlogData.date}</span>
              </p>
              <p className="mb-0">
                <b>Category</b>:- <span>{SingleBlogData.category}</span>
              </p>
              <p className="mb-0">
                <b>City</b>:- <span>{SingleBlogData.city}</span>
              </p>
              <p className="mb-0">
                <b>Area</b>:- <span>{SingleBlogData.Area}</span>
              </p>
              <p className="mb-0">
                <b>Branch Area</b>:- <span>{SingleBlogData.brancharea}</span>
              </p>
              <p className="mb-0">
                <b>DNC Phone</b>:-<span>{SingleBlogData.dncphone}</span>
              </p>
              <p className="mb-0">
                <b>Company</b>:-<span>{SingleBlogData.company}</span>
              </p>
              <p className="mb-0">
                <b>Pincode</b>:- <span>{SingleBlogData.pincode}</span>
              </p>
              <p className="mb-0">
                <b>Time</b>:- <span>{SingleBlogData.time}</span>
              </p>
              <p className="mb-0">
                <b>Branch Pin</b>:- <span>{SingleBlogData.branchpin}</span>
              </p>
              <p className="mb-0">
                <b>Parent ID</b>:- <span>{SingleBlogData.parentid}</span>
              </p>
              <p className="mb-0">
                <b>From Where</b>:-<span>{SingleBlogData.from_where}</span>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LeadDashboardView;
